<template>
  <div>
    <v-btn :loading="loading" @click="uploadImage()" color="info" dark>{{ video ? 'Add Video' : 'Add Image' }}</v-btn>
    <v-btn v-if="image" :loading="loading" @click="$emit('deleteImage')" color="error" dark class="ml-2">{{ video ? 'Delete Video' : 'Delete Image' }}</v-btn>
  </div>
</template>
<script>
import Api from '@/services/Api.js'; 
export default {

  props: {
    boxOffice: {},
    video: {type: Boolean, default: false},
    image: {type: String, default: ''}
  },

  data () {
    return {
      loading: false
    }
  },

  methods: {
    uploadImage() {
      this.loading = true
      var uploader = window.cloudinary.createUploadWidget({
          cloudName: "dimyv3wy5",
          folder: "ticket/"+this.boxOffice.id,
          uploadPreset: this.video ? 'upload_video_wide' : 'upload_image',
          cropping: true,
          multiple: false,
        }, (error, result) => {
          this.loading = false
          if (error) {
            this.$toast.error(" ", error.message, {position: 'bottomCenter', theme: 'dark', backgroundColor : "red"});
          }
          if (result && result.event === "success") {
            this.$emit('selectImage', result.info.secure_url)
          }
        });
      uploader.open();
    }
  }
}
</script>
